<template>
  <div id="photo" class="lightbox" @click.self="closeLightbox">
    <div class="button-wrapper">
      <button>
        <img
          src="../assets/left-arrow.png"
          v-show="!this.hideLeft"
          @click="this.clickLeft()"
        />
      </button>
    </div>
    <img :src="loadImg()" />
    <div class="button-wrapper">
      <button>
        <img
          src="../assets/right-arrow.png"
          v-show="!this.hideRight"
          @click="this.clickRight()"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Photo",
  data() {
    return {
      pictureList: [
        "one.jpg",
        "two.jpg",
        "three.jpg",
        "four.jpg",
        "five.jpg",
        "six.jpg",
        "seven.jpg",
        "eight.jpg",
        "nine.jpg",
        "ten.jpg",
        "eleven.jpg",
        "twelve.jpg",
        "thirteen.jpg",
        "fourteen.jpg",
      ],
      hideRight: false,
      hideLeft: false,
    };
  },
  created() {
    window.addEventListener("keyup", this.handle);
  },
  mounted() {
    var curPhoto = this.$route.params.photo;
    var curIndex = this.pictureList.indexOf(curPhoto);
    if (curIndex == 0) {
      this.hideLeft = true;
    }
    if (curIndex == this.pictureList.length - 1) {
      this.hideRight = true;
    }
  },
  unmounted() {
    window.removeEventListener("keyup", this.handle);
  },
  methods: {
    loadImg() {
      return require(`../assets/pictures/${this.$route.params.photo}`);
    },
    closeLightbox() {
      this.$router.push("/gallery");
    },
    handle(ev) {
      ev.preventDefault();
      var curPhoto = this.$route.params.photo;
      var curIndex = this.pictureList.indexOf(curPhoto);
      var backNextIndex = curIndex - 1;
      var nextIndex = curIndex + 1;

      if (ev.code == "ArrowLeft") {
        if (curIndex != 0 && curIndex > 0) {
          this.$router.push("/photos/" + this.pictureList[backNextIndex]);
        }
        this.checkLeftRightToggles(backNextIndex);
      } else if (ev.code == "ArrowRight") {
        if (curIndex != this.pictureList.length - 1) {
          this.$router.push("/photos/" + this.pictureList[nextIndex]);
        }
        this.checkLeftRightToggles(nextIndex);
      }
    },
    clickLeft() {
      var curPhoto = this.$route.params.photo;
      var curIndex = this.pictureList.indexOf(curPhoto);
      var nextIndex = curIndex - 1;

      if (curIndex != 0 && curIndex > 0) {
        this.$router.push("/photos/" + this.pictureList[nextIndex]);
      }
      this.checkLeftRightToggles(nextIndex);
    },
    clickRight() {
      var curPhoto = this.$route.params.photo;
      var curIndex = this.pictureList.indexOf(curPhoto);
      var nextIndex = curIndex + 1;

      if (curIndex != this.pictureList.length - 1) {
        this.$router.push("/photos/" + this.pictureList[nextIndex]);
      }
      this.checkLeftRightToggles(nextIndex);
    },
    checkLeftRightToggles(nextIndex) {
      this.hideLeftToggle(nextIndex);
      this.hideRightToggle(nextIndex);
    },
    hideLeftToggle(nextIndex) {
      if (nextIndex == 0 || nextIndex < 0) {
        this.hideLeft = true;
      } else {
        this.hideLeft = false;
      }
    },
    hideRightToggle(nextIndex) {
      if (
        nextIndex == this.pictureList.length - 1 ||
        nextIndex == this.pictureList.length
      ) {
        this.hideRight = true;
      } else {
        this.hideRight = false;
      }
    },
  },
};
</script>

<style scoped>
#photo .button-wrapper {
  width: 5%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.lightbox:hover {
  cursor: pointer;
}
.lightbox img {
  margin: auto;
  width: 90%;
}
</style>
